<!--
 * @Author: sun
 * @Date: 2023-05-26 11:17:32
 * @LastEditTime: 2023-05-31 10:29:05
 * @FilePath: \cloud-platform\src\views\coupon\dynamicCode.vue
 * @Description: 
-->
<template>
  <div class="imgcode">
    <h1>优惠券领取</h1>
    <div class="text">
      <p>商家名称：{{ shopName }}</p>
      <p>优惠券名称：{{ name }}</p>
      <p>优惠券类型：{{ couponType == 3 ? '折扣优惠券' : '电子优惠券' }}</p>
      <p>折扣值：{{ discountValue }}</p>
      <p>领取有效期：{{ expireTime }}</p>
      <p>使用说明：请用微信或支付宝扫一扫领取后，领取后点击支付停车费</p>
    </div>
    <el-image
      style="width: 25%; height: 30%; margin-top: 5%; margin-bottom: 1%"
      :src="imageUrl"
      :preview-src-list="[imageUrl]"
    >
      <template #fallback>
        <div class="image-fallback">图片加载失败</div>
      </template>
    </el-image>
    <span class="time">{{ remainingTime }}S后过期</span>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2' // 引入qrcode
export default {
  data() {
    return {
      imageUrl: '',
      k: undefined,
      timer: null,
      name: undefined,
      discountValue: undefined,
      startTime: null,
      remainingTime: 120,
      shopName: undefined,
      couponType: undefined,
      expireTime: undefined,
    }
  },
  created() {
    this.generate()
    this.surplus()
    this.timer = setInterval(() => {
      clearInterval(this.startTime)
      this.remainingTime = 120
      this.surplus()
      this.generate()
    }, 120000)
  },
  methods: {
    // 显示剩余时间
    surplus() {
      this.startTime = setInterval(() => {
        this.remainingTime = --this.remainingTime
      }, 1000)
    },
    generate() {
      const time = Math.floor(Date.now() / 1000)
      console.log(time)
      this.k = this.decimalToBase62(time)
      this.getCode()
    },
    // 时间戳加密
    decimalToBase62(decimalNumber) {
      var characters =
        '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      var base62Value = ''

      while (decimalNumber > 0) {
        var remainder = decimalNumber % 62
        base62Value = characters.charAt(remainder) + base62Value
        decimalNumber = Math.floor(decimalNumber / 62)
      }

      return base62Value
    },
    // 动态二维码
    getCode() {
      if (this.$route.query.discountValue) {
        this.discountValue = this.$route.query.discountValue
      } else {
        this.discountValue = 0
      }
      if (this.$route.query.name) {
        this.name = this.$route.query.name
      } else {
        this.name = ''
      }
      if (this.$route.query.shopName) {
        this.shopName = this.$route.query.shopName
      } else {
        this.shopName = ''
      }

      if (this.$route.query.expireTime) {
        this.expireTime = this.$route.query.expireTime
      } else {
        this.shopName = ''
      }

      this.couponType = this.$route.query.couponType

      const newPath =
        process.env.VUE_APP_WEB_API +
        `/#/pages/scancouponspc/index?id=${this.$route.query.id}&expireTime=${this.$route.query.expireTime}&name=${this.$route.query.name}&discountValue=${this.$route.query.discountValue}&k=${this.k}&pkid=${this.$route.query.pkid}`
      const qrCode = new QRCode(document.createElement('div'), {
        text: newPath,
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      })
      this.imageUrl = qrCode._el.firstChild.toDataURL()
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>
<style lang="scss" scope>
.imgcode {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #000;
  width: 50%;
  height: 70%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  p {
    font-size: 18px;
    font-weight: 700;
  }
  .time {
  }
}
</style>
